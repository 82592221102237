define("discourse/plugins/waze-header/discourse/templates/connectors/user-dropdown-notifications__before/user-dropdown-bell-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a title={{I18n "waze.user_dropdown.title"}}>
    {{dIcon "w-bell"}}
  </a>
  */
  {
    "id": "W4HJBk77",
    "block": "[[[10,3],[15,\"title\",[28,[37,0],[\"waze.user_dropdown.title\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"w-bell\"],null]],[1,\"\\n\"],[13]],[],false,[\"I18n\",\"dIcon\"]]",
    "moduleName": "discourse/plugins/waze-header/discourse/templates/connectors/user-dropdown-notifications__before/user-dropdown-bell-icon.hbs",
    "isStrictMode": false
  });
});